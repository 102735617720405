//俄语
export default {
    commons: {
        copySuccess: "Копирование успешно",
        downloadSuccess: "Скачать успешно",
        downloadBtn: "Загрузчик",
    },
    home: {
        topTitle: "Открывайте, копируйте и загружайте все ваши любимые Emojis",
        topSubTitle: "The Ultimate Destination for Emoji Enthusiasts!",
        searchEmojis: "Поиск эмодзи"
    },
    aiEmojisGenerator: {
        topTitle: "Генератор эмодзи Ai",
        topSubTitle: "Всего было сгенерировано 100 000+ эмодзи!",
        searchPlaceholder: "Please enter a description"
    },
    components: {
        downloadImage: {
            title: "emoji downloader",
            shareGuide: "share To",
        }
    }
}