<template>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
        @select="handleSelect">
        <el-menu-item index="0">
            <a href="https://www.nerd-emoji.com/">
                <h1 class="logo_wrap" :style="{'font-size':deviceType!='pc'?'18px':'26px'}">
                    <el-avatar class="search_icon" :src="require('../assets/icon/nerd.png')" />
                    <div>nerd-emoji</div> 
                </h1>
            </a>
        </el-menu-item>
        <div class="flex-grow"></div>
        <el-sub-menu index="2">
            <template #title>{{ lang }}</template>
            <el-menu-item v-for="item in localeList" :key="item.locale" @click="changeLanguage(item)">
                {{ item.lang }}</el-menu-item>
        </el-sub-menu>
    </el-menu>
</template>

<script>
    import {
        getCookie
    } from '../utils/baseUtil'
    export default {
        data() {
            return {
                deviceType: '',
                activeIndex: '0',
                lang: 'English',
                localeList: [{
                        "lang": 'English',
                        "locale": 'en'
                    },
                    {
                        "lang": '简体中文',
                        "locale": 'zhCn'
                    },
                    {
                        "lang": 'español',
                        "locale": 'es'
                    },
                    {
                        "lang": 'français',
                        "locale": 'fr'
                    },
                    {
                        "lang": 'にほんご',
                        "locale": 'ja'
                    },
                    {
                        "lang": 'português',
                        "locale": 'pt'
                    },
                    {
                        "lang": 'русский',
                        "locale": 'ru'
                    }
                ]
            }
        },
        created() {
            let locale = getCookie("locale") || '';
            let flag = true
            if (locale) {
                for (let item of this.localeList) {
                    if (item.locale == locale) {
                        this.lang = item.lang
                        flag = false
                    }
                }
                if (flag) {
                    this.lang = "English"
                    this.$i18n.locale = "en"
                }
            }
            this.deviceType = this.$store.state.deviceType
        },
        methods: {
            //切换语言
            changeLanguage(localeItem) {
                this.$router.push('/' + localeItem.locale);
                this.$store.commit("setLocale", localeItem.locale)
                this.$i18n.locale = localeItem.locale
                this.lang = localeItem.lang
                window.location.href = '/' + localeItem.locale;
            }
        }
    }
</script>

<style lang="less">
    .el-menu {
        padding: 0 20px;

        .el-menu-item {
            .logo_wrap {
                display: flex;
                align-items: center;
                font-size: 26px;
                background: none;
                color: #000;

                .el-avatar {
                    background: none;
                    display: flex;
                    margin-right: 10px;
                }
            }
        }

        .is-active {
            border-bottom: none !important;
        }
    }

    .flex-grow {
        flex-grow: 1;
    }
</style>