// https://github.com/omniinfer/javascript-sdk
// https://docs.omniinfer.io/api/#details
// https://axios-http.com/zh/docs/post_example
import { OmniinferSDK } from "omniinfer-sdk";
const sdk = new OmniinferSDK("bd134434-4511-483e-adf2-60641f6a37c1");
import axios from 'axios';
const BASE_URL = "https://api.nerd-emoji.com"
// const BASE_URL = "http://127.0.0.1:5000"

//生成emoji图像
export const generateAiEmoji = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            timeout:20000,
            method: 'post',
            url: BASE_URL + '/api/ai/sdxlEmoji',
            data: {
                prompt: params.prompt
            },
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }).then(result => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}

//移除背景
export const removebg = (params) => {
    return new Promise((resolve, reject) => {
        axios({
            timeout:20000,
            method: 'post',
            url: BASE_URL + '/api/ai/removebg',
            data: {
                "imageSrc": params.imageSrc
            },
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
        }).then(result => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}

//文生图
export const handleTxt2Img = (params) => {
    return new Promise((resolve, reject) => {
        sdk.txt2Img({
            "prompt": params.prompt,
            "negative_prompt": params.negative_prompt ? params.negative_prompt : '',
            "model_name": params.model_name ? params.model_name : '',
            "sampler_name": params.sampler_name ? params.sampler_name : '',
            "batch_size": params.batch_size ? params.batch_size : 1,
            "n_iter": params.n_iter ? params.n_iter : 1,
            "steps": params.steps ? params.steps : 20,
            "cfg_scale": params.cfg_scale ? params.cfg_scale : 7,
            "seed": params.seed ? params.seed : -1,
            "height": params.height ? params.height : 512,
            "width": params.width ? params.width : 512,
            "sd_refiner": params.sd_refiner ? params.sd_refiner : [],
            "hr_scale": params.hr_scale ? params.hr_scale : 1,
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
//文生图-异步
export const handleTxt2ImgSync = (params) => {
    return new Promise((resolve, reject) => {
        sdk.txt2ImgSync({
            "prompt": params.prompt,
            "negative_prompt": params.negative_prompt ? params.negative_prompt : '',
            "model_name": params.model_name ? params.model_name : '',
            "sampler_name": params.sampler_name ? params.sampler_name : '',
            "batch_size": params.batch_size ? params.batch_size : 1,
            "n_iter": params.n_iter ? params.n_iter : 1,
            "steps": params.steps ? params.steps : 20,
            "cfg_scale": params.cfg_scale ? params.cfg_scale : 7,
            "seed": params.seed ? params.seed : -1,
            "height": params.height ? params.height : 512,
            "width": params.width ? params.width : 512,
            "sd_refiner": params.sd_refiner ? params.sd_refiner : {},
            "hr_scale": params.hr_scale ? params.hr_scale : 1,
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
//图生图
export const handleImg2img = (params) => {
    return new Promise((resolve, reject) => {
        sdk.img2img({
            "prompt": params.prompt,
            "negative_prompt": params.negative_prompt ? params.negative_prompt : '',
            "model_name": params.model_name ? params.model_name : '',
            "sampler_name": params.sampler_name ? params.sampler_name : '',
            "batch_size": params.batch_size ? params.batch_size : 1,
            "n_iter": params.n_iter ? params.n_iter : 1,
            "steps": params.steps ? params.steps : 20,
            "cfg_scale": params.cfg_scale ? params.cfg_scale : 7,
            "seed": params.seed ? params.seed : -1,
            "height": params.height ? params.height : 512,
            "width": params.width ? params.width : 512,
            "denoising_strength": params.denoising_strength ? params.denoising_strength : 0.9,
            "restore_faces": params.restore_faces ? params.restore_faces : false,
            "init_images": [params.base64Image ? params.base64Image : '']
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
//图生图-异步
export const handleImg2imgSync = (params) => {
    return new Promise((resolve, reject) => {
        sdk.img2imgSync({
            "prompt": params.prompt,
            "negative_prompt": params.negative_prompt ? params.negative_prompt : '',
            "model_name": params.model_name ? params.model_name : '',
            "sampler_name": params.sampler_name ? params.sampler_name : '',
            "batch_size": params.batch_size ? params.batch_size : 1,
            "n_iter": params.n_iter ? params.n_iter : 1,
            "steps": params.steps ? params.steps : 20,
            "cfg_scale": params.cfg_scale ? params.cfg_scale : 7,
            "seed": params.seed ? params.seed : -1,
            "height": params.height ? params.height : 512,
            "width": params.width ? params.width : 512,
            "denoising_strength": params.denoising_strength ? params.denoising_strength : 0.9,
            "restore_faces": params.restore_faces ? params.restore_faces : false,
            "init_images": [params.base64Image ? params.base64Image : '']
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
//图片放大
export const handleUpscale = (params) => {
    return new Promise((resolve, reject) => {
        sdk.upscale({
            //枚举[0,1]:0 = 按 upscaling_resize 缩放，最大图像大小< 2048.1 = 按 upscaling_resize_w 和 upscaling_resize_h 缩放，最大图像大小< 2048
            "resize_mode": params.resize_mode ? params.resize_mode : '0',
            //图像的放大倍数
            "upscaling_resize": params.upscaling_resize ? params.upscaling_resize : '2',
            //目标图像宽度，最大图像大小为2048
            "upscaling_resize_w": params.upscaling_resize_w ? params.upscaling_resize_w : '1024',
            //目标图像高度，最大图像大小为2048
            "upscaling_resize_h": params.upscaling_resize_h ? params.upscaling_resize_h : '1024',
            //裁剪后放大
            "upscaling_crop": params.upscaling_crop ? params.upscaling_crop : true,
            //枚举[ ESRGAN_4x, R-ESRGAN 4x+, R-ESRGAN 4x+ Anime6B ]
            "upscaler_1": params.upscaler_1 ? params.upscaler_1 : 'R-ESRGAN 4x+',
            "upscaler_2": params.upscaler_2 ? params.upscaler_2 : 'R-ESRGAN 4x+',
            //范围 [0~1]，Extras_upscaler_2可见性
            "extras_upscaler_2_visibility": params.extras_upscaler_2_visibility ? params.extras_upscaler_2_visibility : '0.5',
            //范围 [0~1]，GFPGAN 可见性
            "gfpgan_visibility": params.gfpgan_visibility ? params.gfpgan_visibility : '0.5',
            //范围 [0~1]，codeformer可见性
            "codeformer_visibility": params.codeformer_visibility ? params.codeformer_visibility : '0.5',
            //范围 [0~1]，codeformer权重（0 = 最大效果，1 = 最小效果）
            "codeformer_weight": params.codeformer_weight ? params.codeformer_weight : '0.5',
            "image": params.base64Image
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
//图片放大-异步
export const handleUpscaleSync = (params) => {
    return new Promise((resolve, reject) => {
        sdk.upscaleSync({
            //枚举[0,1]:0 = 按 upscaling_resize 缩放，最大图像大小< 2048.1 = 按 upscaling_resize_w 和 upscaling_resize_h 缩放，最大图像大小< 2048
            "resize_mode": params.resize_mode ? params.resize_mode : '0',
            //图像的放大倍数
            "upscaling_resize": params.upscaling_resize ? params.upscaling_resize : '2',
            //目标图像宽度，最大图像大小为2048
            "upscaling_resize_w": params.upscaling_resize_w ? params.upscaling_resize_w : '1024',
            //目标图像高度，最大图像大小为2048
            "upscaling_resize_h": params.upscaling_resize_h ? params.upscaling_resize_h : '1024',
            //裁剪后放大
            "upscaling_crop": params.upscaling_crop ? params.upscaling_crop : true,
            //枚举[ ESRGAN_4x, R-ESRGAN 4x+, R-ESRGAN 4x+ Anime6B ]
            "upscaler_1": params.upscaler_1 ? params.upscaler_1 : 'R-ESRGAN 4x+',
            "upscaler_2": params.upscaler_2 ? params.upscaler_2 : 'R-ESRGAN 4x+',
            //范围 [0~1]，Extras_upscaler_2可见性
            "extras_upscaler_2_visibility": params.extras_upscaler_2_visibility ? params.extras_upscaler_2_visibility : '0.5',
            //范围 [0~1]，GFPGAN 可见性
            "gfpgan_visibility": params.gfpgan_visibility ? params.gfpgan_visibility : '0.5',
            //范围 [0~1]，codeformer可见性
            "codeformer_visibility": params.codeformer_visibility ? params.codeformer_visibility : '0.5',
            //范围 [0~1]，codeformer权重（0 = 最大效果，1 = 最小效果）
            "codeformer_weight": params.codeformer_weight ? params.codeformer_weight : '0.5',
            "image": params.base64Image
        }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
//获取模型
export const handleGetModels = () => {
    return new Promise((resolve, reject) => {
        sdk.getModels().then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}
