<template>
    <el-footer>
        <div class="left">
        </div>
        <div class="right">
            Copyright © 2023 nerd-emoji.com All rights reserved.
        </div>
    </el-footer>
</template>

<script>

</script>

<style lang="less">
    .el-footer {
        background: #fff;
        line-height: 60px;
        text-align: center;
        color: #292D32;
    }
</style>