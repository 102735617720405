import { createStore } from 'vuex'
import { setCookie } from '../utils/baseUtil'

export default createStore({
  state() {
    return {
      "deviceType": '', //设备类型
      "locale": 'en'
    }
  },
  mutations: {
    //设置设备类型
    setDeviceType(state, deviceType) {
      state.deviceType = deviceType
    },
    //设置语言
    setLocale(state, locale) {
      state.locale = locale
      setCookie('locale', locale, { expires: 9999999999999 })
    },
  },
  actions: {
  },
  modules: {
  }
})
