<template>
    <pre>{{ sitemap }}</pre>
</template>

<script>
    export default {
        data() {
            return {
                sitemap: ''
            };
        },
        mounted() {
            this.loadSitemap();
        },
        methods: {
            async loadSitemap() {
                try {
                    const response = await fetch('/sitemap.xml');
                    this.sitemap = await response.text();
                } catch (error) {
                    console.error('Failed to load sitemap:', error);
                }
            }
        }
    };
</script>