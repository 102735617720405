<template>
  <Header></Header>
  <router-view></router-view>
  <Footer></Footer>
</template>
<script>
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'
  export default {
    components: {
      Header,
      Footer
    },
    created() {
      this.getResource()
    },
    methods: {
      //判断用户访问来源
      getResource() {
        var userAgent = navigator.userAgent;
        // 判断是否是移动设备
        var isMobile = /Mobi|Android/i.test(userAgent);
        // 判断是否是平板设备
        var isTablet = /Tablet|iPad/i.test(userAgent);
        // 判断是否是桌面设备
        // 输出结果
        var deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : 'pc'
        this.$store.commit("setDeviceType", deviceType)
      }
    }
  }
</script>