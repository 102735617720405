<template>
    <div class="no_data_wrap">
        <el-image class="" :src="require('../assets/image/no_data.png')" mode="aspectFit"
            lazy-load="false" binderror="" bindload="">
        </el-image>
    </div>
</template>

<style lang="less">
    .no_data_wrap {
        width: 100%;
        height: 100%;
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-image {
            width: 300px;
            height: 300px;
        }
    }
</style>