<template>
  <div class="container">
    <div class="img_wrap">
      <el-image :style="{opacity: 0.8,height:deviceType!='pc'?'240px':''}"
        :src="require('../assets/image/top_banner.png')" />
      <div class="emoji_generate" :style="{'top':deviceType!='pc'?'120px':'200px'}">
        <div class="text_wrap">
          <h2 class="title" :style="{'font-size':deviceType!='pc'?'20px':'36px'}"> {{ $t('home.topTitle') }}</h2>
          <h2 class="subtitle" :style="{'font-size':deviceType!='pc'?'14px':'18px'}">{{$t('home.topSubTitle')}}</h2>
        </div>
        <div class="input_wrap"
          :style="{'width':deviceType!='pc'?'75%':'35%',margin:deviceType!='pc'?'20px auto':'30px auto'}">
          <div class="left">
            <el-input v-model="searchInput" :placeholder="searchPlaceHolder" clearable @input="handleInputSearch"
              @clear="handleClear" />
          </div>
          <button class="right" @click="handleSearchEmojis">
            <el-image class="search_icon" :src="require('../assets/icon/search.png')" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <NoData v-show="isShowNoData"></NoData>
  <div class="emoji_container" v-for="(category, categoryName) in allEmojiList" :key="categoryName">
    <h2>{{ categoryName }}</h2>
    <div v-for="(group, groupName) in category" :key="groupName">
      <h3>{{ groupName }}</h3>
      <div class="emoji_wrap">
        <div class="emoji_item" v-for="emoji in group" :key="emoji.describe">
          <p class="item" :title="emoji.describe"> {{emoji.code}}</p>
          <div class="guide">
            <button class="btn" @click="handleCopyText(emoji.code)">
              <el-avatar size="small" shape="square" :src="require('../assets/icon/copy.png')" />
            </button>
            <button class="btn" @click="handleDownload(emoji)">
              <el-avatar size="small" shape="square" :src="require('../assets/icon/download3.png')" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-backtop :right="30" :bottom="120" />
  <div class="footer_tabs" v-if="locale=='en'"
    :style="{'width':deviceType!='pc'?'90%':'70%',margin:deviceType!='pc'?'0 5%':'0 15%'}">
    <button class="menu_item" :class="item.isSelect?'isSelect':''" v-for="(item,index) in menuList" :key="index"
      @click="handleSelectType(index,item)">{{ item.icon }}</button>
  </div>
  <DownloadImage @closeDialog="handleCloseDialog" :showFlag="isShowDownloadPopup" :emojiText="downloadItem.emojiText"
    :describe="downloadItem.describe"></DownloadImage>
</template>

<script>
  import enAllEmoji from '../lib/en-AllEmoji.json'
  import esAllEmoji from '../lib/es-AllEmoji.json'
  import frAllEmoji from '../lib/fr-AllEmoji.json'
  import jaAllEmoji from '../lib/ja-AllEmoji.json'
  import ptAllEmoji from '../lib/pt-AllEmoji.json'
  import ruAllEmoji from '../lib/ru-AllEmoji.json'
  import zhCnAllEmoji from '../lib/zhCn-AllEmoji.json'
  import DownloadImage from '../components/DownloadImge.vue'
  import NoData from '../components/NoData.vue'
  import {
    getCookie
  } from '../utils/baseUtil'
  import {
    ElMessage
  } from 'element-plus'
  export default {
    components: {
      DownloadImage,
      NoData
    },
    data() {
      return {
        allEmojiList: [],
        emojiListByCategory: [],
        isShowNoData: false,
        tempEmojiList: [],
        currentGroup: '',
        searchInput: '',
        deviceType: 'pc',
        locale: 'en',
        isShowDownloadPopup: false,
        searchPlaceHolder: this.$t("home.searchEmojis"),
        downloadItem: {
          emojiText: '',
          describe: ''
        },
        menuList: [{
          "path": 'smileys-emotion-emoji',
          "group": 'Smileys Emotion',
          "icon": '🤪'
        }, {
          "path": 'people-body-emoji',
          "group": 'People Body',
          "icon": '✌️'
        }, {
          "path": 'component-emoji',
          "group": 'Component',
          "icon": '🦱'
        }, {
          "path": 'animals-nature-emoji',
          "group": 'Animals Nature',
          "icon": '🐶'
        }, {
          "path": 'food-drink-emoji',
          "group": 'Food Drink',
          "icon": '🍗'
        }, {
          "path": 'travel-places-emoji',
          "group": 'Travel Places',
          "icon": '🛝'
        }, {
          "path": 'activities-emoji',
          "group": 'Activities',
          "icon": '🎄'
        }, {
          "path": 'objects-emoji',
          "group": 'Objects',
          "icon": '🎸'
        }, {
          "path": 'symnols-emoji',
          "group": 'Symbols',
          "icon": '🔞'
        }, {
          "path": 'flags-emoji',
          "group": 'Flags',
          "icon": '🚩'
        }],
      }
    },
    created() {
      this.deviceType = this.$store.state.deviceType
      this.initLanguage()
    },
    methods: {
      //初始化语言
      initLanguage() {
        var locale = this.$route.path.substring(1) || ''
        if (locale && locale == 'en' || locale == 'es' || locale == 'fr' || locale == 'ja' || locale == 'pt' ||
          locale == 'ru' || locale == 'zhCn') {
          this.$store.commit("setLocale", locale)
          this.$i18n.locale = locale
          this.lang = locale
        } else {
          locale = getCookie("locale") || this.$store.state.locale || 'en'
        }
        this.locale = locale
        if (locale == "en") {
          this.allEmojiArray = enAllEmoji
        } else if (locale == "es") {
          this.allEmojiArray = esAllEmoji
        } else if (locale == "fr") {
          this.allEmojiArray = frAllEmoji
        } else if (locale == "ja") {
          this.allEmojiArray = jaAllEmoji
        } else if (locale == "pt") {
          this.allEmojiArray = ptAllEmoji
        } else if (locale == "ru") {
          this.allEmojiArray = ruAllEmoji
        } else if (locale == "zhCn") {
          this.allEmojiArray = zhCnAllEmoji
        } else {
          this.allEmojiArray = enAllEmoji
        }
        this.initEmojiList(this.allEmojiArray, true)
      },
      //初始化emoji原始数据
      initEmojiList(dataArray, isInit) {
        let result = {};
        let allEmojiList = dataArray
        allEmojiList.forEach(data => {
          this.isShowNoData = false
          if (!result[data.group]) {
            result[data.group] = {};
          }
          if (!result[data.group][data.subgroup]) {
            result[data.group][data.subgroup] = [];
          }
          result[data.group][data.subgroup].push({
            describe: data.describe,
            code: data.code,
          });
        })
        this.allEmojiList = result
        if (isInit) {
          this.tempEmojiList = result
        }
      },
      //关闭弹窗
      handleCloseDialog() {
        this.isShowDownloadPopup = false
      },
      //点击下载表情包
      handleDownload(item) {
        this.downloadItem.describe = item.describe
        this.downloadItem.emojiText = item.code
        this.isShowDownloadPopup = true
      },
      //监听输入
      handleInputSearch(event) {
        this.searchInput = event
      },
      //监听清空
      handleClear() {
        if (this.currentGroup) {
          var tempEmojiList = {}
          tempEmojiList[this.currentGroup] = this.tempEmojiList[this.currentGroup]
          this.allEmojiList = tempEmojiList
        } else {
          this.initEmojiList(this.allEmojiArray, true)
        }
      },
      //搜索表情
      handleSearchEmojis() {
        if (this.searchInput) {
          var tempEmojiList = []
          this.allEmojiArray.forEach(v => {
            if (v.describe.includes(this.searchInput) || v.group.includes(this.searchInput) || v.subgroup.includes(
                this.searchInput)) {
              tempEmojiList.push(v)
            }
          })
          if (tempEmojiList.length == 0) {
            this.isShowNoData = true
          }
          this.allEmojiList = tempEmojiList
        } else {
          this.allEmojiList = this.allEmojiArray
        }
        this.initEmojiList(this.allEmojiList, false)
      },
      //切换类型
      handleSelectType(index, item) {
        this.searchInput = ''
        this.currentGroup = item.group
        this.menuList.forEach((v, i) => {
          if (i == index) {
            v.isSelect = true
          } else {
            v.isSelect = false
          }
        })
        var tempEmojiList = {}
        tempEmojiList[item.group] = this.tempEmojiList[item.group]
        this.allEmojiList = tempEmojiList
      },
      //一键复制文本
      async handleCopyText(text) {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(text)
            .then(() => {
              ElMessage({
                message: this.$t('commons.copySuccess'),
                type: 'success',
              })
            })
            .catch(err => {
              ElMessage({
                message: 'Failed to copy text:' + err,
                type: 'error',
              })
            });
        } else {
          ElMessage({
            message: 'Clipboard operations are not supported in the current environment!',
            type: 'error',
          })
        }
      },
    }
  }
</script>

<style lang="less">
  body {
    padding-bottom: 120px;
  }

  .container {

    .img_wrap {
      position: relative;
      width: 100%;

      .emoji_generate {
        position: absolute;
        top: 200px;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        .text_wrap {
          .title {
            font-size: 40px;
            font-weight: 550;
            margin: 0;
            padding: 0;
          }

          .subtitle {
            font-size: 18px;
          }
        }

        .input_wrap {
          margin: 30px auto;
          width: 35%;
          position: relative;

          .left {
            .el-input {
              .el-input__wrapper {
                border-radius: 25px;
                height: 50px;
                box-shadow: none;
              }
            }
          }

          .right {
            background: none;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 30px;
            bottom: 5px;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .emoji_container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: center;

    .emoji_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .emoji_item {
        background: #fff;
        margin: 10px;
        width: 90px;
        height: 90px;
        font-size: 60px;
        border-radius: 10px;
        position: relative;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 0;
        }

        .guide {
          position: absolute;
          bottom: 0;
          font-size: 16px;
          left: 50%;
          transform: translate(-50%, 0);
          width: 100%;
          height: 35px;
          line-height: 30px;
          background: #fff;
          border-radius: 0 0 10px 10px;
          display: none;
          opacity: 0.9;

          .btn {
            margin: 0;
            padding: 0;
            border: none;
            width: 50%;
            height: 100%;
            background: none;
            padding: 0;
            box-shadow: none;

            .el-avatar {
              background: none;
            }
          }
        }
      }

      .emoji_item:hover {
        background-color: #000;

        .guide {
          display: block;
        }
      }
    }
  }


  .footer_tabs {
    padding: 5px 0;
    display: flex;
    position: fixed;
    bottom: 30px;
    overflow-x: scroll;
    justify-content: flex-start;
    height: 60px;
    border-radius: 10px;
    margin: 0 15%;
    background: rgba(255, 255, 255, 0.17);
    box-shadow: 0px 2px 37px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(25px);
    width: 70%;

    .menu_item {
      display: flex;
      font-size: 40px;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      background: none;
      padding: 0 30px;
      box-shadow: #000;
    }

    .isSelect {
      background: rgba(0, 0, 0, 0.25);
    }
  }
</style>