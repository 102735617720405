//中文
export default {
    commons: {
        copySuccess: "复制成功。",
        downloadSuccess: "下载成功。",
        downloadBtn: "下载器",
    },
    home: {
        topTitle: "发现、复制和下载您最喜爱的所有表情符号",
        topSubTitle: "表情符号爱好者的终极目的地！",
        searchEmojis: "搜索表情符号"
    },
    aiEmojisGenerator: {
        topTitle: "AI表情符号生成器",
        topSubTitle: "共生成了100,000+个表情符号！",
        searchPlaceholder: "请输入描述"
    },
    components: {
        downloadImage: {
            title: "表情符号下载器",
            shareGuide: "分享至",
        }
    }
}