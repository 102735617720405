import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Sitemap from '@/views/Sitemap.vue';
import AiEmojisGenerator from '@/views/AiEmojisGenerator.vue';
import NotFound from '@/views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'nerd emoji. Free Emoji Copy and Download | Explore a Vast Collection of Emojis.',
      description: 'Welcome to our emoji website! Discover a wide range of emojis that cover almost every category, including smileys, animals, food, activities, and more. Enjoy the freedom to copy and download emojis for free. ',
      keywords: 'emoji, emoticons, smileys, copy emoji, download emoji, free emojis, emoji website, vast collection of emojis, emoji copy and paste.'
    },
  },
  {
    path: '/ai-emojis-generator',
    name: 'AiEmojisGenerator',
    component: AiEmojisGenerator,
    meta: {
      title: 'AI emoji Generator, a free emoji making and downloading tool.',
      description: 'A super fun online meme maker that can turn your creativity and ideas into memes in no time.',
      keywords: ''
    }
  },
  {
    path: '/zhCn',
    name: 'zhCnHome',
    component: Home,
    meta: {
      title: 'nerd emoji. 免费复制和下载表情符号 | 探索大量表情符号。',
      description: '欢迎访问我们的表情符号网站！探索几乎涵盖所有类别的各种表情符号，包括笑脸、动物、食物、活动等。您可以自由复制和免费下载表情符号。',
      keywords: "表情符号、表情符号、笑脸、复制表情符号、下载表情符号、免费表情符号、表情符号网站、表情符号大集合、表情符号复制和粘贴。"
    }
  },
  {
    path: '/en',
    name: 'enHome',
    component: Home,
    meta: {
      title: 'nerd emoji. Free Emoji Copy and Download | Explore a Vast Collection of Emojis.',
      description: 'Welcome to our emoji website! Discover a wide range of emojis that cover almost every category, including smileys, animals, food, activities, and more. Enjoy the freedom to copy and download emojis for free. ',
      keywords: 'emoji, emoticons, smileys, copy emoji, download emoji, free emojis, emoji website, vast collection of emojis, emoji copy and paste.'
    }
  },
  {
    path: '/es',
    name: 'esHome',
    component: Home,
    meta: {
      title: 'Emoji empollón. Copie y descargue emoji gratis | Explore una vasta colección de emojis',
      description: '¡Bienvenido a nuestra web de emojis! Descubre una amplia gama de emojis que cubren casi todas las categorías, incluyendo smileys, animales, comida, actividades y mucho más. Disfruta de la libertad de copiar y descargar emojis gratis. ',
      keywords: 'emoji, emoticons, smileys, copy emoji, download emoji, free emojis, emoji website, vast collection of emojis, emoji copy and paste.'
    }
  },
  {
    path: '/fr',
    name: 'frHome',
    component: Home,
    meta: {
      title: 'nerd emoji. Free Emoji Copy and Download | Explore a Vast Collection of Emojis',
      description: 'Welcome to our emoji website ! Découvrez une large gamme d emojis couvrant presque toutes les catégories, y compris les smileys, les animaux, la nourriture, les activités et bien plus encore.Profitez de la liberté de copier et de télécharger des emojis gratuitement. ',
      keywords: 'emoji, emoticons, smileys, copy emoji, download emoji, free emojis, emoji website, vastes collections d emojis, emoji copy and paste.'
    }
  },
  {
    path: '/ja',
    name: 'jaHome',
    component: Home,
    meta: {
      title: 'オタク絵文字。無料絵文字コピー＆ダウンロード｜絵文字の膨大なコレクションを探る',
      description: '絵文字のウェブサイトへようこそ！スマイリー、動物、食べ物、アクティビティなど、ほぼすべてのカテゴリをカバーする幅広い絵文字を発見してください。絵文字をコピーして無料でダウンロードする自由をお楽しみください。',
      keywords: '絵文字、顔文字、スマイリー、コピー絵文字、ダウンロード絵文字、無料絵文字、絵文字ウェブサイト、絵文字の膨大なコレクション、絵文字コピー＆ペースト。',
    }
  },
  {
    path: '/pt',
    name: 'ptHome',
    component: Home,
    meta: {
      title: 'emoji nerd. Copiar e descarregar emojis grátis | Explorar uma vasta coleção de emojis',
      description: 'Bem-vindo ao nosso sítio Web de emojis! Descubra uma vasta gama de emojis que abrange quase todas as categorias, incluindo smileys, animais, comida, actividades e muito mais. Aproveite a liberdade de copiar e descarregar emojis gratuitamente. ',
      keywords: 'emoji, emoticons, smileys, copiar emoji, descarregar emoji, emojis grátis, site de emoji, vasta coleção de emojis, copiar e colar emoji.'
    }
  },
  {
    path: '/ru',
    name: 'ruHome',
    component: Home,
    meta: {
      title: "Ботаник эмодзи. Бесплатная копия и загрузка эмодзи | Изучите обширную коллекцию эмодзи.",
      description: "Добро пожаловать на наш сайт эмодзи! Откройте для себя широкий спектр эмодзи, которые охватывают практически все категории, включая смайлики, животных, еду, деятельность и многое другое. Наслаждайтесь свободой копирования и загрузки эмодзи бесплатно. ",
      keywords: "emoji, emoticons, smileys, copy emoji, download emoji, free emojis, emoji website, vast collection of emojis, emoji copy and paste."
    }
  },
  {
    path: '/sitemap.xml',
    name: 'Sitemap',
    component: Sitemap
  },
  // 404页面 - 必须放路由末尾
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    redirect: '/'
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

//初始化页面标题、关键词、描述等
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const metaTags = document.getElementsByTagName('meta')
  for (let i = 0; i < metaTags.length; i++) {
    const tag = metaTags[i]
    if (to.meta.description && tag.name === 'description') {
      tag.content = to.meta.description
    }
    if (to.meta.keywords && tag.name === 'keywords') {
      tag.content = to.meta.keywords
    }
  }
  next()
})

export default router
