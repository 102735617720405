<template>
    <div class="top_container">
        <h1 class="title" :style="{'font-size':deviceType=='pc'?'40px':'20px'}">
            {{ $t("aiEmojisGenerator.topTitle") }}
        </h1>
        <h2 :style="{'font-size':deviceType=='pc'?'20px':'16px'}">
            {{ $t("aiEmojisGenerator.topSubTitle") }}
        </h2>
        <div class="input_wrap"
            :style="{'width':deviceType!='pc'?'75%':'35%',margin:deviceType!='pc'?'20px auto':'30px auto'}">
            <div class="left">
                <el-input v-model="inputText" :placeholder="searchPlaceholder" clearable
                    @input="handleInputSearch" />
            </div>
            <button class="right" @click="handleGenerator" v-loading="isLoading">
                <el-image class="search_icon" :src="require('../assets/icon/enter.png')" />
            </button>
        </div>
    </div>
    <div class="content_container">
        <el-card shadow="hover" v-for="(item,index) in generatorAiEmojis" :key="index"
            :style="{'width':deviceType=='pc'?'22%':'85%',}">
            <div class="image_wrap">
                <el-image :title="item.name" :src="item.imageSrc" fit="cover" lazy />
                <div class="guide">
                    <button class="btn" @click="handleDownload(item)">
                        <el-avatar size="small" shape="square" :src="require('../assets/icon/download2.png')" />
                    </button>
                    <button class="btn" @click="handleDownload(item)">
                        <el-avatar size="small" shape="square" :src="require('../assets/icon/share2.png')" />
                    </button>
                </div>
            </div>
            <p :title="item.name" class="name">propmt:{{ item.name }}</p>
        </el-card>
    </div>
    <div class="content_container">
        <el-card shadow="hover" v-for="(item,index) in aiEmojis" :key="index"
            :style="{'width':deviceType=='pc'?'22%':'85%',}">
            <div class="image_wrap">
                <el-image :title="item.name" :src="item.imageSrc" fit="cover" lazy />
                <div class="guide">
                    <button class="btn" @click="handleDownload(item)">
                        <el-avatar size="small" shape="square" :src="require('../assets/icon/download2.png')" />
                    </button>
                    <button class="btn" @click="handleDownload(item)">
                        <el-avatar size="small" shape="square" :src="require('../assets/icon/share2.png')" />
                    </button>
                </div>
            </div>
            <p :title="item.name" class="name">propmt:{{ item.name }}</p>
        </el-card>
    </div>
    <DownloadImage @closeDialog="handleCloseDialog" :showFlag="isShowDownloadPopup" :filePath="downloadItem.imageSrc"
        :describe="downloadItem.prompt"></DownloadImage>
</template>

<script>
    import aiEmojis from '../lib/aiEmojis.json'
    import DownloadImage from '../components/DownloadImge.vue'
    import {
        generateAiEmoji,
        removebg,
    } from '../api/sdApi.js'
    import {
        ElMessage
    } from 'element-plus'
    export default {
        components: {
            DownloadImage
        },
        data() {
            return {
                isShowDownloadPopup: false,
                deviceType: '',
                inputText: '',
                aiEmojis: [],
                generatorAiEmojis: [],
                isLoading: false,
                searchPlaceholder:this.$t("aiEmojisGenerator.searchPlaceholder"),
                emojiItem: {
                    "prompt": '',
                    "imageSrc": ''
                },
                downloadItem: {
                    filePath: '',
                    describe: ''
                },
            }
        },
        created() {
            this.aiEmojis = aiEmojis
            this.deviceType = this.$store.state.deviceType
        },
        methods: {
            //关闭弹窗
            handleCloseDialog() {
                this.isShowDownloadPopup = false
            },
            //点击下载表情包
            handleDownload(item) {
                this.downloadItem.prompt = item.name
                this.downloadItem.imageSrc = item.imageSrc
                this.isShowDownloadPopup = true
            },
            //监听输入
            handleInputSearch(event) {
                this.inputText = event
            },
            //监听生成表情图片
            async handleGenerator() {
                if (!this.inputText.trim) {
                    return;
                }
                if (!this.isLoading) {
                    this.isLoading = true
                    await generateAiEmoji({
                        prompt: this.inputText
                    }).then(res => {
                        if (res.data[0]) {
                            this.isLoading = false
                            this.handleRemovebg(res.data[0])
                        }
                    }).catch((err) => {
                        this.isLoading = false
                        ElMessage({
                            message: 'system error:' + err,
                            type: 'error',
                        })
                    })
                }
            },
            //移除背景
            async handleRemovebg(imageSrc) {
                this.iniGeneratorEmoji(this.inputText, '')
                await removebg({
                    "imageSrc": imageSrc
                }).then(res => {
                    this.isLoading = false
                    this.iniGeneratorEmoji(this.inputText, res.data)
                }).catch(() => {
                    this.isLoading = false
                    this.iniGeneratorEmoji(this.inputText, imageSrc)
                })
            },
            //初始化生成的emoji
            iniGeneratorEmoji(text, imageSrc) {
                this.emojiItem.name = text
                this.emojiItem.imageSrc = imageSrc
                this.generatorAiEmojis.push(this.emojiItem)
                this.inputText = ''
            }
        }
    }
</script>

<style lang="less">
    .top_container {
        width: 100%;
        height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(360deg, #abdcff, #0396ff);

        h1 {
            font-size: 40px;
        }

        .input_wrap {
            margin: 30px auto;
            width: 35%;
            position: relative;

            .left {
                .el-input {
                    .el-input__wrapper {
                        border-radius: 25px;
                        height: 50px;
                        box-shadow: none;
                    }
                }
            }

            .right {
                background: none;
                margin: 0;
                padding: 0;
                position: absolute;
                right: 30px;
                bottom: 5px;
                width: 40px;
                height: 40px;
            }
        }
    }

    .content_container {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 50px 20px;
        justify-content: space-around;

        .el-card {
            border-radius: 10px;
            margin: 10px 0;
            width: 250px;
            height: 100px;

            .el-card__body {
                padding: 0;
                display: flex;

                .image_wrap {
                    position: relative;
                    margin: 10px;
                    width: 80px;
                    height: 80px;
                    background: #F6F6F6;
                    border-radius: 10px;

                    .el-image {
                        width: 80px;
                        height: 80px;
                        border-radius: 10px;
                    }

                    .guide {
                        position: absolute;
                        bottom: 0;
                        font-size: 16px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 100%;
                        height: 35px;
                        line-height: 30px;
                        background: rgba(0, 0, 0, 0.5);
                        border-radius: 0 0 10px 10px;
                        display: none;

                        .btn {
                            margin: 0;
                            padding: 0;
                            border: none;
                            width: 50%;
                            height: 100%;
                            background: none;
                            padding: 0;
                            box-shadow: none;

                            .el-avatar {
                                background: none;
                            }
                        }
                    }
                }

                .name {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                }
            }

            .el-card__body:hover {
                .image_wrap {
                    .guide {
                        display: block;
                    }
                }
            }
        }
    }
</style>