//西班牙语
export default {
    comunes: {
        copySuccess: "Copiar con éxito",
        downloadSuccess: "Descargar con éxito",
        downloadBtn: "Descargador",
    },
    home: {
        topTitle: "Descubre, copia y descarga todos tus emojis favoritos",
        topSubTitle: "¡El destino definitivo para los entusiastas de los emojis!",
        searchEmojis: "Busca Emojis"
    },
    aiEmojisGenerator: {
        topTitle: "Generador de emojis Ai",
        topSubTitle: "¡Se generaron más de 100.000 emojis!",
        searchPlaceholder: "Por favor, introduzca una descripción"
    },
    componentes: {
        downloadImage: {
            title: "descargador de emoji",
            shareGuide: "compartir a",
        }
    }
}