<template>
  <el-dialog v-model="dialogVisible" :title="$t('components.downloadImage.title')" :width="deviceType!='pc'?'85%':'60%'"
    align-center @close="closeDialog">
    <div :class="deviceType!='pc'?'mobile_image_wrap':'image_wrap'">
      <div class="left" :style="{background:backgroundColor}" id="emoji_img">
        <el-image v-if="filePath" :src="filePath"></el-image>
        <span v-if="emojiText" class="emoji_code">{{ emojiText }}</span>
      </div>
      <div class="right">
        <p class="text_desc">{{ describe }}</p>
        <div class="color_wrap">
          <button class="color_item" :style="{background:item}" v-for="(item) in colorList" :key="item"
            @click="handleChangeColor(item)"></button>
          <el-color-picker v-model="color" @change="handleChangeColor" />
        </div>
        <div class="download_wrap">
          <button class="download_btn" @click="downloadFile">{{ $t("commons.downloadBtn") }}</button>
        </div>
        <div class="share_wrap">
          <p>{{$t("components.downloadImage.shareGuide")}}:</p>
          <div class="share_item">
            <el-avatar @click="handleShare(item.href)" :src="item.imageSrc" v-for="item in shareList" :key="item">
            </el-avatar>
          </div>
        </div>
      </div>
    </div>
    <template>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import html2canvas from 'html2canvas'
  import {
    ElMessage
  } from 'element-plus'
  import youtubeLogo from '../assets/image/share_logo/youtube.png'
  import twitterLogo from '../assets/image/share_logo/twitter.png'
  import telegramLogo from '../assets/image/share_logo/telegram.png'
  import redditLogo from '../assets/image/share_logo/reddit.png'
  import facebookLogo from '../assets/image/share_logo/facebook.png'
  import linkedinLogo from '../assets/image/share_logo/linkedin.png'
  export default {
    data() {
      return {
        backgroundColor: '',
        deviceType: 'pc',
        dialogVisible: false, //是否展示下载弹窗
        colorList: ['#D91E06', '#F5EB2B', '#17FB2A', '#1296DB', '#12227A', '#D4237A', '#FFFFFF', '#E6E6E6', '#CDCDCD',
          '#8A8A8A', '#707070', '#515151', '#2c2c2c'
        ],
        color: '#409EFF',
        shareList: [{
            name: 'youtube',
            imageSrc: youtubeLogo,
            href: 'https://www.youtube.com/'
          },
          {
            name: 'twitter',
            imageSrc: twitterLogo,
            href: 'https://twitter.com/'
          }, {
            name: 'telegram',
            imageSrc: telegramLogo,
            href: 'https://www.telegram.com/',
          }, {
            name: 'reddit',
            imageSrc: redditLogo,
            href: 'https://www.reddit.com/'
          }, {
            name: 'facebook',
            imageSrc: facebookLogo,
            href: 'https://www.facebook.com/'
          }, {
            name: 'linkedin',
            imageSrc: linkedinLogo,
            href: 'https://www.linkedin.com/'
          }
        ]
      }
    },
    props: {
      showFlag: {
        type: Boolean,
        default: false
      },
      //文件路径
      filePath: {
        type: String,
        default: ''
      },
      //emoji
      emojiText: {
        type: String,
        default: ''
      },
      describe: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      }
    },
    watch: {
      showFlag(newVal) {
        this.dialogVisible = newVal
      }
    },
    created() {
      this.deviceType = this.$store.state.deviceType
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog')
      },
      //选择颜色
      handleChangeColor(event) {
        if (event) {
          this.backgroundColor = event
        }
      },
      //分享
      handleShare(text) {
        this.handleCopyText("https://nerd-emoji.com")
        window.open(text, '_blank');
      },
      //一键复制文本
      async handleCopyText(text) {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(text)
            .then(() => {
              ElMessage({
                message: 'Copied to your clipboard.',
                type: 'success',
              })
            })
            .catch(err => {
              ElMessage({
                message: 'Failed to copy text:' + err,
                type: 'error',
              })
            });
        }
      },
      //下载图片
      downloadFile() {
        html2canvas(document.querySelector("#emoji_img"), {
          backgroundColor: this.backgroundColor ? this.backgroundColor : null,
          useCORS: true
        }).then(function (canvas) {
          if (canvas) {
            let imgData = canvas.toDataURL('image/png'); //将canvas内容转化为base64编码的图像数据  
            let link = document.createElement('a'); //创建一个新的a元素  
            link.href = imgData; //设置a元素的href属性为imgData  
            link.download = 'nerd-emoji.png'; //设置下载文件的文件名  
            link.click(); //模拟点击a元素，触发下载操作
            ElMessage({
              message: this.$t('commons.downloadSuccess'),
              type: 'success',
            })
          }
        });
      }
    }
  }
</script>

<style lang="less">
  body {
    background: #FAFAFB;
  }

  .el-dialog {
    .image_wrap {
      display: flex;

      .left {
        width: 300px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .el-image {
          width: 300px;
          height: 300px;
        }

        .emoji_code {
          width: 300px;
          height: 300px;
          font-size: 280px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .right {
        margin: 0 20px;
        width: 60%;
        display: flex;
        flex-direction: column;

        .text_desc {
          width: 100%;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .color_wrap {
          margin-top: 10px;
          display: flex;

          .color_item {
            border-radius: 0;
            width: 32px;
            height: 32px;
          }
        }

        .download_wrap {
          display: flex;
          justify-content: flex-end;

          .download_btn {
            margin: 20px 25px 0 0;
            width: 180px;
            height: 40px;
            background: #1769FF;
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            line-height: 40px;
          }
        }

        .share_wrap {
          .share_item {
            display: flex;

            .el-avatar {
              margin: 0 10px;
            }
          }
        }
      }
    }
  }

  .mobile_image_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .left {
      width: 300px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .el-image {
        width: 300px;
        height: 300px;
      }

      .emoji_code {
        width: 300px;
        height: 300px;
        font-size: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .right {
      width: 100%;
      display: flex;
      flex-direction: column;

      .text_desc {
        width: 100%;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      .color_wrap {
        margin-top: 10px;
        display: flex;

        .color_item {
          border-radius: 0;
          width: 32px;
          height: 32px;
        }
      }

      .download_wrap {
        display: flex;
        justify-content: flex-end;

        .download_btn {
          margin: 20px 25px 0 0;
          width: 180px;
          height: 40px;
          background: #1769FF;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          line-height: 40px;
        }
      }

      .share_wrap {
        .share_item {
          display: flex;

          .el-avatar {
            margin: 0 10px;
          }
        }
      }
    }
  }
</style>