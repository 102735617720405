//英语
export default {
    commons: {
        copySuccess: "Copy successfully.",
        downloadSuccess: "Download Successfully.",
        downloadBtn: "downloader",
    },
    home: {
        topTitle: "Discover, Copy, and Download All Your Favorite Emojis",
        topSubTitle: "The Ultimate Destination for Emoji Enthusiasts!",
        searchEmojis: "Search Emojis"
    },
    aiEmojisGenerator: {
        topTitle: "Ai emojis generator",
        topSubTitle: "A total of 100,000 + emojis were generated!",
        searchPlaceholder: "Please enter a description"
    },
    components: {
        downloadImage: {
            title: "emoji downloader",
            shareGuide: "share To",
        }
    }
}