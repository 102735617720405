//日语
export default {
    commons: {
        copySuccess: "コピーに成功しました",
        downloadSuccess: "ダウンロードに成功しました",
        downloadBtn: "ダウンローダー",
    },
    home: {
        topTitle: "お気に入りの絵文字を発見,コピー,ダウンロード",
        topSubTitle: "絵文字愛好家のための究極の目的地！",
        searchEmojis: "絵文字を検索"
    },
    aiEmojisGenerator: {
        topTitle: "Ai絵文字ジェネレータ",
        topSubTitle: "A total of 100,000 + emojis were generated!",
        searchPlaceholder: "説明を入力してください"
    },
    components: {
        downloadImage: {
            title: "絵文字ダウンローダー",
            shareGuide: "共有先"
        }
    }
}