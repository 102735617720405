//葡萄牙语
export default {
    commons: {
        copySuccess: "Copiar com sucesso.",
        downloadSuccess: "Descarregar com êxito.",
        downloadBtn: "downloader",
    },
    home: {
        topTitle: "Descubra, copie e transfira todos os seus Emojis favoritos",
        topSubTitle: "O destino final para os entusiastas de Emoji!",
        searchEmojis: "Pesquisar Emojis"
    },
    aiEmojisGenerator: {
        topTitle: "Gerador de emojis Ai",
        topSubTitle: "Foi gerado um total de mais de 100 000 emojis!",
        searchPlaceholder: "Por favor, introduza uma descrição"
    },
    componentes: {
        downloadImage: {
            title: "downloader de emoji",
            shareGuide: "partilhar para",
        }
    }
}