import { createI18n } from 'vue-i18n'
import { getCookie } from '../utils/baseUtil'

import elementEn from 'element-plus/dist/locale/en.mjs'
import elementEs from 'element-plus/dist/locale/es.mjs'
import elementFr from 'element-plus/dist/locale/fr.mjs'
import elementJa from 'element-plus/dist/locale/ja.mjs'
import elementPt from 'element-plus/dist/locale/pt.mjs'
import elementRu from 'element-plus/dist/locale/ru.mjs'
import elementZhCn from 'element-plus/dist/locale/zh-cn.mjs'

import loaclEn from './en' //英语
import loaclEs from './es' //西班牙语
import loaclFr from './fr' //法语
import loaclJa from './ja' //日语
import loaclPt from './pt' //葡萄牙语
import loaclRu from './ru' //俄语
import loaclZhCn from './zhCn' //简体中文
const messages = {
    en: {
        ...elementEn,
        ...loaclEn
    },
    es: {
        ...elementEs,
        ...loaclEs
    },
    fr: {
        ...elementFr,
        ...loaclFr
    },
    ja: {
        ...elementJa,
        ...loaclJa
    },
    pt: {
        ...elementPt,
        ...loaclPt
    },
    ru: {
        ...elementRu,
        ...loaclRu
    },
    zhCn: {
        ...elementZhCn,
        ...loaclZhCn
    },
}

export const i18n = createI18n({
    locale: getCookie('locale') || 'en',
    legacy: false,
    globalInjection: true, // 全局注册$t方法
    messages
})