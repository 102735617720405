//法语
export default {
    commons: {
        copySuccess: "Copie réussie",
        downloadSuccess: "Télécharger avec succès",
        downloadBtn: "téléchargeur",
    },
    home: {
        topTitle: "Découvrez, copiez et téléchargez tous vos Emojis préférés",
        topSubTitle: "La destination ultime pour les passionnés d'emojis !",
        searchEmojis: "Rechercher des Emojis"
    },
    aiEmojisGenerator: {
        topTitle: "Générateur d'émojis",
        topSubTitle: "Un total de 100 000 + emojis ont été générés !",
        searchPlaceholder: "Veuillez saisir une description"
    },
    composants: {
        downloadImage: {
            title: "téléchargeur d'emoji",
            shareGuide: "share To",
        }
    }
}