import Cookies from 'js-cookie';
// 设置Cookie  
export const setCookie = (key, value, options) => {
    // 函数的options参数用于设置关于Cookie的不同选项。这个参数是一个对象，可以包含以下属性：
    // expires：设置Cookie的过期时间。可以是一个日期对象，或者以毫秒为单位的过期时间。如果未设置，Cookie默认会在浏览器关闭时过期。
    // path：定义了Cookie的路径。默认为创建Cookie的页面所在的路径。
    // domain：定义了Cookie的域。默认为当前域名。
    // secure：一个布尔值，表示是否仅通过HTTPS传输Cookie。默认为false。
    // httpOnly：一个布尔值，表示是否仅允许服务器访问Cookie，而不允许客户端脚本访问。默认为false。
    // sameSite：定义了Cookie是否在跨站点请求中使用。可以是'Strict'、'Lax'或'None'。默认为'None'。
    Cookies.set(key, value, options);
}

// 读取Cookie  
export const getCookie = (key) => {
    return Cookies.get(key);
}

//检查图片地址是否有效
export const checkImgExists = (imgurl) => {
    return new Promise(function (resolve, reject) {
        var ImgObj = new Image();
        ImgObj.src = imgurl;
        ImgObj.onload = function (res) {
            resolve(res);
        }
        ImgObj.onerror = function (err) {
            reject(err)
        }
    })
}
